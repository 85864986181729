import React, { useEffect } from 'react'
import queryString from 'query-string'

import {
    Admin,
    fetchUtils,
    Resource,
    defaultTheme,
    CustomRoutes,
} from 'react-admin'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { restclient, authProvider } from '@thefront/pandipackV2'
import Layout from './components/Layout'
import { userReducer } from './customReducers'
import Login from './components/Login.js'
import TenantLogs from './resources/tenant/TenantLogs'
import NewIntegrationShow from './resources/integration/IntegrationShow'
import OldIntegrationShow from './resources/integration/OldIntegrationShow'
import TenantEdit from './resources/tenant/TenantEdit'
import TenantList from './resources/tenant/TenantList'
import IntegrationList from './resources/integration/IntegrationList'
import { API_URI, NO_COOKIE, ThemeConfigContextProvider } from './themeConfig'
import { cleanUserName } from './components/componentHelpers'
import { createTheme, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Route } from 'react-router-dom'
import ExternalConnectorForm from './components/Auth/ExternalConnectorForm'

import StylesProvider from '@mui/styles/StylesProvider'
import createGenerateClassName from '@mui/styles/createGenerateClassName'

import i18nProvider from '@thefront/pandipackV2'
// TODO: update when HistoryRouter becomes stable, or maybe revisit
// how we deep link, to possibly have all frontends on the same router
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { isEmpty } from 'lodash-es'

const basename = '/' + window.location.pathname.split('/')[1]

const history = createBrowserHistory()

// Prevent className collision in production
const generateClassName = createGenerateClassName({
    productionPrefix: 'prod-imp',
})

export default ({ keycloak, namespace, theme, deepLinkInfo }) => {
    const keycloakClient = authProvider(keycloak)

    // TODO remove once all customer are using the new version
    const IntegrationShow = theme.configs.marketplaceSettings
        .IntegrationShow2024
        ? NewIntegrationShow
        : OldIntegrationShow

    const Resources = () => [
        <Resource
            name="integrations"
            list={IntegrationList}
            show={(props) => <IntegrationShow {...props} />}
        />,
        <Resource
            name="tenants"
            show={(props) => <TenantLogs {...props} />}
            list={TenantList}
            create={(props) => <IntegrationShow {...props} />}
            edit={(props) => <TenantEdit {...props} undoable={false} />}
        />,
    ]

    // Sends a message to the parent containing height data for dynamic sizing
    useEffect(() => {
        let timeoutId

        const config = {
            attributes: true,
            attributeOldValue: false,
            characterData: true,
            characterDataOldValue: false,
            childList: true,
            subtree: true,
        }

        const handleHeightChange = () => {
            const pageRoot =
                document.querySelector('.MuiScopedCssBaseline-root') ||
                document.querySelector('#pageRoot')
            if (!pageRoot) return
            window.parent.postMessage(
                {
                    type: 'CONTENT_HEIGHT_CHANGE',
                    height: pageRoot.offsetHeight,
                },
                '*'
            )
        }
        if (theme.configs.marketplaceSettings.pageTitle) {
            document.title = theme.configs.marketplaceSettings.pageTitle
        }
        if (theme.configs.marketplaceSettings.favicon) {
            const favicon = document.querySelector("link[rel~='icon']")
            favicon.href = theme.configs.marketplaceSettings.favicon
        }

        const observer = new MutationObserver(() => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(handleHeightChange, 200)
        })

        observer.observe(document.body, config)

        return () => {
            observer.disconnect()
        }
    }, [
        theme.configs.marketplaceSettings.favicon,
        theme.configs.marketplaceSettings.pageTitle,
    ])

    console.debug(
        'Namespace: ',
        process.env.REACT_APP_OVERRIDE_REALM || namespace
    )

    const qs = queryString.parse(window.location.search)
    const myApps = qs['my_apps']
    const backgroundConnectToken = qs['token']
    //If we have a deep linked tenant -> redirect to the tenant's edit
    //If we have a deep linked Integration -> redirect to the list of tenants of the integration
    if (deepLinkInfo) {
        const { deepLink, id } = deepLinkInfo
        if (deepLink === 'TENANT') {
            history.push(`${basename}/tenants/${id}/edit/connection-settings`)
        } else if (deepLink === 'INTEGRATION') {
            history.push(`${basename}/tenants`, {
                integration: `${id}`,
            })
        } else if (deepLink === 'TENANT-CREATE') {
            history.push(`${basename}/tenants/create/${id}`)
        }
    }

    // If the user is requesting to go to my apps, go to the tenantsList endpoint.
    if (myApps === 'true') {
        history.push(`${basename}/tenants`)
    }

    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' })
        }
        //If token expired, get new one
        keycloak
            .updateToken(5)
            .then(function (refreshed) {
                if (refreshed) {
                    sessionStorage.setItem('token', keycloak.token)
                }
            })
            .catch(function () {
                console.error(
                    'Failed to refresh the token, or the session has expired'
                )
            })

        options.headers.set('Authorization', `Bearer ${keycloak.token}`)

        // TODO: Need this cause we  can't derive the namespace in the python api
        //  We should add it to the token so we don't need to worry about this in the future
        options.headers.set('X-NAMESPACE', namespace)
        options.headers.set('no-cookie', NO_COOKIE)

        return fetchUtils.fetchJson(url, options)
    }

    const externalIntegrations = keycloak.idTokenParsed.external_integrations
    console.debug('tenantName: ', keycloak.idTokenParsed.username)
    const userLogin = keycloak.idTokenParsed.username
    const aid = keycloak.idTokenParsed.aid
    const userID = keycloak.idTokenParsed.sub
    console.debug('userEmail: ', userLogin)
    const muiTheme = createTheme({
        ...defaultTheme,
        ...theme.configs,
        breakpoints: {
            values: {
                xs: 0,
                sm: 960,
                md: 1024,
                lg: 1280,
                xl: 1440,
                xxl: 1800,
            },
        },
        spacing: 8,
        overrides: {
            MuiButton: {
                contained: {
                    color: 'white !important',
                    '&:disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                    },
                },
            },
        },
        components: {
            ...defaultTheme.components,
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: 256,
                    },
                },
            },
        },
    })

    const xti = process.env.REACT_APP_EXTRA_TENANT_INFO
        ? JSON.parse(process.env.REACT_APP_EXTRA_TENANT_INFO)
        : keycloak.idTokenParsed.extra_tenant_info

    const tid = keycloak.idTokenParsed.tenant_id
    console.debug('idTokenParsed tid: ', tid)

    const reducer = combineReducers({
        user: userReducer,
    })

    const preloadedState = {
        user: {
            userName: cleanUserName(userLogin),
            namespace: namespace,
            externalIntegrations: externalIntegrations,
            aid: cleanUserName(aid),
            userID: userID,
            xti: xti,
            tid: tid,
            token: backgroundConnectToken,
            singleIntegrationView:
                window.location.pathname.includes('/tenants/create/') &&
                !isEmpty(qs['tenant']),
        },
    }

    const store = configureStore({
        reducer,
        devTools: true,
        preloadedState,
    })

    // pull in custom css file, which customer users upload in the admin dash
    useEffect(() => {
        const staticUrl =
            process.env.REACT_APP_STATIC_URI ||
            `https://static.${window.location.hostname
                .split('.')
                .slice(1)
                .join('.')}`
        const head = document.head
        const link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.href = `${staticUrl}${basename}/css/custom.css`
        link.onload = () => console.log(`custom css file loaded ${link.href}`)
        link.onerror = () =>
            console.log(
                `custom css file load error, it probably doesn't exist!`
            )
        head.appendChild(link)
        return () => {
            head.removeChild(link)
        }
    }, [])

    return (
        <ThemeConfigContextProvider value={theme}>
            <StylesProvider generateClassName={generateClassName}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={muiTheme}>
                        <Provider store={store}>
                            <HistoryRouter
                                history={history}
                                basename={basename}
                            >
                                <Admin
                                    disableTelemetry
                                    layout={(props) => (
                                        <Layout theme={muiTheme} {...props} />
                                    )}
                                    authProvider={keycloakClient}
                                    i18nProvider={i18nProvider}
                                    dataProvider={restclient(
                                        API_URI,
                                        httpClient
                                    )}
                                    theme={muiTheme}
                                    title="IMP"
                                    loginPage={Login} //custom login page (currently blank)
                                >
                                    <CustomRoutes>
                                        <Route
                                            path="/connector/auth"
                                            element={<ExternalConnectorForm />}
                                        />
                                        <Route
                                            path="/connector/tenants/:tenantId/edit/connection-form"
                                            element={<ExternalConnectorForm />}
                                        />
                                    </CustomRoutes>
                                    {Resources}
                                </Admin>
                            </HistoryRouter>
                        </Provider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </StylesProvider>
        </ThemeConfigContextProvider>
    )
}
