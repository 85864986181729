import React, { useState, useEffect } from 'react'
import ConnectGrid from './ConnectGrid'
import { Grid, Typography, Button, Box } from '@mui/material'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { SearchBar, genSearchIndex } from '../../components/InstaSearchBar'
import TenantList from '../tenant/TenantList'
import { useThemeConfig } from '../../themeConfig'
import { useGetList, useRedirect } from 'react-admin'
import { CategoryList } from '../category/CategoryList'
import { cleanUserName } from '../../components/componentHelpers'
import CategoryRow from './IntegrationByCategory'
import IntegrationCarousel from './IntegrationCarousel'
import { IntegrationHeroBanner } from './IntegrationHeroBanner'

export const IntegrationList = ({
    userName,
    aid,
    xti,
    externalIntegrations,
}) => {
    const redirect = useRedirect()
    const [selectedCategory, setSelectedCategory] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [searchView, setSearchView] = useState(false)
    const [filteredIntegrations, setFilteredIntegrations] = useState([])
    const [categoryRecord, setCategoryRecord] = useState()

    const { data: integrations, isLoading: integrationsLoading } = useGetList(
        'integrations',
        {
            filter: {
                limit: 500,
                approval_status: 'APPROVED',
                marketplace_settings__published: true,
                type__in: JSON.stringify(['EXTERNAL', 'INTERNAL']),
                with_status: false,
            },
        }
    )
    const {
        data: integrationLabels,
        isLoading: integrationLabelsLoading,
    } = useGetList('integrationlabels', {
        sort: { field: 'order_index', order: 'ASC' },
        filter: {
            label_type__in: JSON.stringify([
                'CATEGORY',
                'SUBCATEGORY',
                'BREAK',
            ]),
            show: true,
            limit: 500,
            sort_by: 'order_index__ASC',
        },
    })

    // Get the tenants so we can hide the "my apps" button from users
    // with no tenants. Only need to know if there's one
    const { data: tenants } = useGetList('tenants', {
        sort: {
            field: 'createdDate',
            order: 'DESC',
        },
        filter: {
            limit: 1,
            [`connected_users__in`]: JSON.stringify([
                `${cleanUserName(userName)}`,
                `${cleanUserName(aid)}`,
            ]),
        },
    })

    const { data: categories, isLoading } = useGetList('categories')

    // remove categories with no integrations from the list of labels to display in the sidebar
    const [visibleCategories, setVisibleCategories] = useState([])
    useEffect(() => {
        const categoriesToList = []
        categories?.forEach((cat) => {
            const subcatsToList = cat.subcategories
                ?.filter((subcat) => subcat.integrations.length)
                .map((subcat) => subcat.name)
            if (subcatsToList?.length) {
                categoriesToList.push(subcatsToList)
            }
            if (cat.integrations?.length || subcatsToList?.length) {
                categoriesToList.push(cat.name)
            }
        })
        setVisibleCategories(
            Object.values(integrationLabels || {}).filter(
                (label) =>
                    label.labelType.toLowerCase() === 'section break' ||
                    categoriesToList.flat().includes(label.name)
            )
        )
    }, [categories, integrationLabels])

    useEffect(() => {
        const enabledIntegrations = xti?.enable_integrations
        genSearchIndex(integrations)

        integrations &&
            (selectedCategory === 'Other'
                ? setFilteredIntegrations(
                      categories?.find((cat) => cat.name === selectedCategory)
                          .integrations
                  )
                : setFilteredIntegrations(
                      Object.values(integrations).filter(
                          (i) =>
                              // If enabled_integrations exists in JWT, we filter out integrations not in list
                              (!enabledIntegrations ||
                                  enabledIntegrations.includes(
                                      i.id.toString()
                                  )) &&
                              (!searchView || searchResults.includes(i.id)) &&
                              (!selectedCategory ||
                                  i.categories?.find((cat) =>
                                      cat.name.includes(selectedCategory)
                                  ))
                      )
                  ))
        setCategoryRecord(
            categories?.find((cat) => cat.name === selectedCategory)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, searchResults, xti, integrations, searchView])

    const getSearchList = (searchResults) => {
        setSearchResults(searchResults)
        setSearchView(true)
    }

    const clearSearchList = () => {
        setSearchResults([])
        setSearchView(false)
    }

    const theme = useThemeConfig()
    const { marketplaceSettings } = theme?.configs

    function shouldDisplayButton() {
        const jwt_show_app = get(xti, 'show_my_apps', true)

        return (
            tenants &&
            jwt_show_app &&
            marketplaceSettings.separateApps &&
            marketplaceSettings.displaySeparateAppsButton &&
            (Object.keys(tenants).length > 0 || externalIntegrations.length > 0)
        )
    }

    const flattenIntegrations = (subcategories, parentIntegrations) => {
        for (let sub of subcategories) {
            let { integrations } = sub
            integrations.length &&
                integrations.forEach((int) =>
                    parentIntegrations.some(
                        (integration) => integration.id === int.id
                    )
                        ? null
                        : parentIntegrations.push(int)
                )
        }
        return parentIntegrations
    }

    const getFeaturedIntegrations = (integrations) => {
        return integrations.filter(
            (integration) => integration.marketplaceSettings?.isFeatured
        )
    }

    return (
        <Box
            id="pageRoot"
            className={'pandium-integration-list'}
            sx={(theme) => ({
                flex: theme?.marketplaceSettings?.fullWidthMarketplace
                    ? '1'
                    : 'none',
                width: '776px',
                [theme.breakpoints.up('sm')]: {
                    width: '896px',
                },
                [theme.breakpoints.up('md')]: {
                    width: '960px',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '1120px',
                },
                [theme.breakpoints.up('xl')]: {
                    width: '1280px',
                },
                [theme.breakpoints.up('xxl')]: {
                    width: '1608px',
                },
            })}
        >
            {theme?.configs?.marketplaceSettings?.heroBanner?.integrationList
                ?.enabled && (
                <IntegrationHeroBanner
                    marketplaceSettings={marketplaceSettings}
                />
            )}
            <Box
                className={'pandium-header'}
                sx={{
                    marginBottom: '40px',
                }}
            >
                <Grid
                    direction="row"
                    container
                    justifyContent="space-between"
                    alignItems="start"
                    sx={{
                        marginTop: theme?.configs?.marketplaceSettings
                            ?.heroBanner?.integrationList?.enabled
                            ? '50px'
                            : '0',
                    }}
                >
                    <Grid item>
                        <Typography
                            className={'pandium-main-text'}
                            sx={{
                                padding: '0 0 10px 0',
                            }}
                            variant="h3"
                        >
                            {marketplaceSettings.marketplaceTitle}
                        </Typography>
                    </Grid>
                    {shouldDisplayButton() && (
                        <Grid item>
                            <Button
                                className={'pandium-primary-button'}
                                variant="contained"
                                color="primary"
                                onClick={() => redirect('/tenants')}
                                sx={{ width: '164px' }}
                            >
                                {marketplaceSettings.myAppsButtonLabel ??
                                    'My apps'}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Typography className="pandium-sub-text" variant="subtitle1">
                    {marketplaceSettings.marketplaceSubheader}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {marketplaceSettings.displayCarousel &&
                    !integrationsLoading && (
                        <IntegrationCarousel
                            integrations={getFeaturedIntegrations(integrations)}
                            marketplaceSettings={marketplaceSettings}
                        />
                    )}
            </Box>
            {!marketplaceSettings.separateApps && (
                <Box>
                    <TenantList noHeaders loaded={false} />
                    <Typography sx={{ margin: '0 0 20px 0' }} variant="h5">
                        {marketplaceSettings.moreAppsButtonLabel ??
                            'Get more apps'}
                    </Typography>
                </Box>
            )}
            {!integrationsLoading && !integrationLabelsLoading && !isLoading && (
                <div style={{ display: 'flex', marginBottom: '25px' }}>
                    {marketplaceSettings.hasCategories && (
                        <CategoryList
                            selected={selectedCategory}
                            title={marketplaceSettings.categoriesTitle}
                            setSelectedCategory={setSelectedCategory}
                            integrationLabels={visibleCategories}
                        />
                    )}
                    <Box sx={{ width: '100%' }} className={'pandium-body'}>
                        {marketplaceSettings.hasSearchbar ? (
                            <SearchBar
                                handler={getSearchList}
                                clear={clearSearchList}
                                placeholder={
                                    marketplaceSettings.searchbarText ??
                                    'Search integrations'
                                }
                            />
                        ) : null}
                        {!marketplaceSettings.hasCategories || searchView ? (
                            <ConnectGrid
                                searchView={searchView}
                                integrations={filteredIntegrations}
                            />
                        ) : selectedCategory && !filteredIntegrations.length ? (
                            'No integrations to show.'
                        ) : selectedCategory &&
                          categoryRecord?.subcategories?.length ? (
                            <div>
                                {categoryRecord && (
                                    <ConnectGrid
                                        searchView={searchView}
                                        integrations={
                                            categoryRecord?.integrations
                                        }
                                    />
                                )}
                                {categoryRecord?.subcategories.map(
                                    (subcategory) => (
                                        <CategoryRow
                                            key={subcategory.name}
                                            category={subcategory}
                                            setSelectedCategory={
                                                setSelectedCategory
                                            }
                                        />
                                    )
                                )}
                            </div>
                        ) : !selectedCategory ? (
                            categories.map((parent) => {
                                parent.subcategories?.length &&
                                    flattenIntegrations(
                                        parent.subcategories,
                                        parent.integrations
                                    )
                                return (
                                    <CategoryRow
                                        key={parent.name}
                                        category={parent}
                                        setSelectedCategory={
                                            setSelectedCategory
                                        }
                                    />
                                )
                            })
                        ) : (
                            <ConnectGrid
                                searchView={searchView}
                                integrations={filteredIntegrations}
                            />
                        )}
                    </Box>
                </div>
            )}
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        userName: state.user.userName,
        aid: state.user.aid,
        xti: state.user.xti,
        externalIntegrations: state.user.externalIntegrations || [],
        namespace: state.user.namespace,
    }
}

export default connect(mapStateToProps)(IntegrationList)
