import React, { useState, useRef, useEffect } from 'react'
import {
    IconButton,
    MenuList,
    MenuItem,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    Typography,
} from '@mui/material'
import { isEmpty } from 'lodash-es'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useThemeConfig } from '../../themeConfig'

export default function IntegrationCornerMenu({ id, supportUrl, redirect }) {
    const theme = useThemeConfig()
    const marketplaceSettings = theme.configs.marketplaceSettings
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const cornerMenuItems = [
        {
            text:
                marketplaceSettings.connectionSettingsLabel ??
                'Connections settings',
            path: '/edit/connection-settings',
        },
        {
            text: marketplaceSettings.syncLink ?? 'Sync schedule',
            path: '/edit/schedule',
        },
        {
            text: marketplaceSettings.logsLabel ?? 'Sync logs',
            path: '/show',
        },
    ]
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleClick = (event, path) => {
        handleClose(event)
        redirect(path)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }
        prevOpen.current = open
    }, [open])

    return (
        <div>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-label="corner-menu-button"
                aria-haspopup="true"
                variant="contained"
                onClick={handleToggle}
                size="large"
            >
                <MoreVertIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="left-start"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper aria-label="corner-menu">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    onKeyDown={handleListKeyDown}
                                >
                                    {cornerMenuItems.map((item, index) => {
                                        return (
                                            item && (
                                                <MenuItem
                                                    key={index}
                                                    value={item.text}
                                                    {...item}
                                                    sx={{
                                                        display: 'flex',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                    onClick={(e) =>
                                                        handleClick(
                                                            e,
                                                            `/tenants/${id}` +
                                                                item.path
                                                        )
                                                    }
                                                >
                                                    <Typography
                                                        sx={{ fontSize: 14 }}
                                                    >
                                                        {' '}
                                                        {item.text}{' '}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        )
                                    })}
                                    {!isEmpty(supportUrl) && (
                                        <MenuItem
                                            key={cornerMenuItems.length}
                                            value={
                                                marketplaceSettings.supportUrlText ??
                                                'Get support'
                                            }
                                            onClick={(e) => {
                                                handleClose(e)
                                                window.open(
                                                    `${supportUrl}`,
                                                    '_blank'
                                                )
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {' '}
                                                {'Get support'}{' '}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
